<template>
  <div class="survey">
    <!-- Header -->
    <h2 class="greeting">
      {{ $t("survey.greeting", { username: $user.username }) }}
    </h2>

    <p class="mt-3 mb-10">{{ $t("survey.description") }}</p>

    <!-- Spinner -->
    <spinner v-if="$loading('surveys/fetch')" center large class="mt-10" />

    <template v-else-if="survey">
      <v-form v-model="formValid">
        <!-- Questions -->
        <ol>
          <SurveyQuestion
            v-for="(question, i) in survey.questions"
            v-model="responses[i]"
            :key="question.id"
            :question="question"
            class="mb-7"
          />

          <div>
            <li class="question">{{ $t("survey.commentInput") }}</li>

            <v-textarea
              v-model="suggestion"
              outlined
              :placeholder="$t('survey.commentInputPlaceholder')"
              hide-details
              class="mt-5"
            />
          </div>
        </ol>
      </v-form>

      <!-- ApiResponse -->
      <api-response :response="formResponse" class="mt-10" />

      <!-- Submit -->
      <div class="text-center my-10">
        <v-btn
          color="primary"
          x-large
          width="200"
          :disabled="!formValid"
          :loading="$loading('surveys/complete') === survey.id"
          @click="submit"
        >
          {{ $t("common.submit") }}
        </v-btn>
      </div>
    </template>
  </div>
</template>

<script>
import SurveyQuestion from "@/components/app/survey/SurveyQuestion.vue";

export default {
  components: { SurveyQuestion },

  data: () => ({
    survey: null,
    responses: [],
    suggestion: "",
    formValid: false,
    formResponse: null,
  }),

  created() {
    this.fetchData();
  },

  methods: {
    fetchData() {
      this.$store
        .dispatch("surveys/fetch")
        .then(({ data }) => {
          const survey = data[0];

          if (survey.completed_at) {
            return this.$emit("next", { alreadyCompleted: true });
          }

          this.initResponses(survey);
          this.survey = survey;
        })
        .catch(() => this.$toast.error("Error while fetching surveys."));
    },

    initResponses(survey) {
      this.responses = [];

      for (let i = 0; i < survey.questions.length; ++i) {
        this.responses.push({
          survey_question_id: survey.questions[i].id,
          survey_answer_id: null,
        });
      }
    },

    submit() {
      const payload = {
        responses: this.responses,
      };

      if (this.suggestion) {
        payload.user = {
          suggestion: this.suggestion,
        };
      }

      this.$store
        .dispatch("surveys/complete", { id: this.survey.id, payload })
        .then(() => {
          this.$emit("next");
        })
        .catch((err) => {
          this.formResponse = err;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.greeting {
  font-weight: bold;
  font-size: 30px;
}

:deep .v-textarea fieldset {
  border-color: white !important;
}
</style>
