<template>
  <div class="survey-question">
    <!-- Question -->
    <li class="question">{{ question.question }}</li>

    <!-- Ratins -->
    <small v-if="isRating" class="font-size-14 d-block mt-2">
      {{ $t("survey.ratings") }}
    </small>

    <!-- Answers -->
    <v-radio-group
      v-model="model.survey_answer_id"
      :rules="[$rules.required]"
      class="answers"
      :row="isRating"
    >
      <v-radio
        v-for="answer in question.answers"
        :key="answer.id"
        :label="answer.answer"
        :value="answer.id"
      />
    </v-radio-group>
  </div>
</template>

<script>
export default {
  props: {
    question: Object,
    value: [Object],
  },

  computed: {
    model: {
      set(val) {
        this.$emit("input", val);
      },

      get() {
        return this.value;
      },
    },

    isRating() {
      return (
        this.question.answers.length === 5 &&
        this.question.answers[0].answer == 1 &&
        this.question.answers[4].answer == 5
      );
    },
  },

  created() {
    if (!this.model) {
      this.model = {
        survey_answer_id: null,
        survey_question_id: this.question.id,
      };
    }
  },
};
</script>

<style lang="scss">
// Moved to global scope for comment/suggestion question styling
.survey {
  .question {
    font-weight: 500;
    font-size: 18px;
  }
}
</style>

<style lang="scss" scoped>
.answers {
  :deep {
    .v-input--radio-group__input {
      gap: 10px;
    }
  }
}
</style>
